.stackbox {
  display: flex;
  flex-direction: column;
  align-items: center; /* center items vertically */
  justify-content: center; /* center items horizontally */
  color: rgba(0, 0, 0, 0.55);
}
.dropdownLocation {
  border: none;
  outline: 0px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  color: rgba(0, 0, 0, 0.55);
}
.selectoption {
  border: none;
  outline: 0px;
}
.phone {
  padding: 10px 0 0 15px;
  color: rgba(0, 0, 0, 0.55);
}

.dropdownBtn {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0.375rem;
  border: 0;

  font-size: 15px;
  /* padding: 5px; */
  color: rgba(0, 0, 0, 0.55);
  cursor: pointer;
  transition: color 0.2s ease-in-out, transform 0.05s ease-in-out;
}
.dropdownBtn:hover {
  color: rgba(0, 0, 0, 0.8);
}
.dropdownBtn:active {
  color: rgba(0, 0, 0, 9.5);
  transform: scale(0.95);
  transition: color 0.2s ease-in-out, transform 0.05s ease-in-out;
}
.dropdownMenu {
  text-align: center;
  list-style-type: none;
  background-color: #fff;
  border-color: var(--bs-border-color-translucent) !important;
  border: 1px solid;
  border-radius: 0.375rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  left: -50px;
  min-width: 180px;
  width: 210px;
  color: rgb(63, 63, 63);
  max-height: 200px;
  overflow-x: hidden;
}
.dropdownLink {
  color: rgb(63, 63, 63);
}
.dropdownLink:hover {
  font-size: 18px;
  color: rgb(69, 188, 0);
  cursor: pointer;
}

.labelHover:hover {
  font-size: 18px;
  color: rgb(69, 188, 0);
  cursor: pointer;
}
.labelHover:active {
  transform: scale(0.95);
  transition: color 0.2s ease-in-out, transform 0.05s ease-in-out;
}
.progressBar {
  margin-top: 10px;
}

.dropdownContainer {
  position: relative;
}

.dropdownBtn {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0.375rem;
  border: 0;
  text-align: center;
  font-size: 18px;
  /* padding: 5px; */
  color: rgba(0, 0, 0, 0.55);
  cursor: pointer;
  transition: color 0.2s ease-in-out, transform 0.05s ease-in-out;
}
.dropdownBtn:hover {
  color: rgba(0, 0, 0, 0.8);
}
.dropdownBtn:active {
  color: rgba(0, 0, 0, 9.5);
  transform: scale(0.95);
  transition: color 0.2s ease-in-out, transform 0.05s ease-in-out;
}
.dropdownMenu {
  list-style-type: none;
  background-color: #fff;
  position: absolute;
  border-color: var(--bs-border-color-translucent) !important;
  border: 1px solid;
  border-radius: 0.375rem;
  width: 130px;
  color: #000000;
}
.itemContainer {
  display: flex;
  justify-content: space-between;
  margin: 5px;
}
.dropdownChkbx {
  margin-left: auto;
}
.item {
  cursor: pointer;
  user-select: none;
  margin-left: -2rem;
}
.menuCloseBtnItem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menuCloseBtn {
  background-color: #28a745;
  border-radius: 0.375rem;
  border: 0;
  color: aliceblue;
  margin: 5px;
  margin-left: -2rem;
}
.selectedLocationsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.selectedLocationItem {
  margin: 5px 10px 0 0;
  display: inline-block;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.55);
  padding: 0px 0px;
  font-size: 14.5px;
}

.removeLocationButton {
  background-color: #dc3545;
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  height: 20px;
  line-height: 1;
  margin-left: 5px;
  padding: 0;
  width: 20px;
}

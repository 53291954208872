.fadeRule {
  height: 1px;
  background-color: #e6e6e6;
  width: 50%;
  margin: 0;
  background-image: linear-gradient(left, white 100%, #e6e6e6 50%, white 98%);
  background-image: -o-linear-gradient(
    left,
    white 100%,
    #e6e6e6 50%,
    white 98%
  );
  background-image: -moz-linear-gradient(
    left,
    white 100%,
    #e6e6e6 50%,
    white 98%
  );
  background-image: -webkit-linear-gradient(
    left,
    white 100%,
    #e6e6e6 50%,
    white 98%
  );
  background-image: -ms-linear-gradient(
    left,
    white 100%,
    #e6e6e6 50%,
    white 98%
  );
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right bottom,
    color-stop(1, white),
    color-stop(0.5, #e6e6e6),
    color-stop(0.98, white)
  );
}

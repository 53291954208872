.speciesList {
  display: flex;
  flex-wrap: wrap;
}

.speciesListItems {
  box-sizing: border-box;
  padding: 8px;
  width: 50%;
}

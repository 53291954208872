.loginCard {
  width: 380px;
  height: 520px;
  padding-left: 35px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  align-items: center;
  box-sizing: border-box;
}

.loginButton {
  background-color: #28a745;
  border: none;
  color: white;
  padding: 10px 32px;

  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  width: 100%;
  border-radius: 5px;
}
.activeLoginButton {
  background-color: #8fc49c;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  width: 100%;
  border-radius: 5px;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}
.offscreen {
  position: absolute;
  left: -9999px;
}
.loginTitle {
  color: #28a745 !important;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}
.spinner {
  animation: spin infinite 2.5s linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loginLayout {
  width: 100%;

  height: 100%;
  position: fixed;
  background-color: #035418 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  overflow-x: hidden;
}
/* @media (min-width: 768px) {
  .loginCard {
    width: 600px;
    padding-left: 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    align-items: center;
    box-sizing: border-box;
  }
} */

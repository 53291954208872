.sidenav {
  height: 100%;
  width: 15%;
  margin-top: 5%;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: rgb(0, 75, 36);
  overflow-x: hidden;

  padding-top: 60px;
  transition: 0.5s;
}
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidenav a:hover {
  color: #f1f1f1;
}
/* main {
  margin-left: 225px !important;
} */

.sidebar {
  padding: 15px 0 0 0;
  min-width: 228px;
  top: 100px;
  bottom: 0;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #013f22;
}
.sidebar a {
  font-size: 15px;
  color: #cdcdcd;
  font-family: sans-serif, Poppins;
}
.active {
  font-weight: bold;
  color: rgb(92, 186, 63) !important;
}
.notActive {
  color: rgb(255, 255, 0);
}

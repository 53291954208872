@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600;800&display=swap");
table {
  height: 100%;
  text-align: center;
  font-weight: 600 !important;
  font-family: "Poppins";
  font-size: 14px;
  margin-bottom: 0 !important;
}
thead th {
  height: 47px;
  min-height: 45;
  font-family: "Poppins" !important;
  font-weight: 800 !important;
}
.BLDSBtn {
  border-radius: 50% !important;
  box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%),
    0 1px 18px 0 rgb(0 0 0 / 12%);

  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  user-select: none;
  outline: none;
  border: none;
  text-align: center;
  overflow: visible;
  width: 46px;
  height: 46px;
  color: #028649;
}

table td {
  vertical-align: middle;
}
.blueGrade {
  background-color: #0000ff !important;
  color: white !important;
}
.greenGrade {
  background-color: #00b04f !important;
  color: black !important;
}
.cardSize {
  width: 24rem;
  height: 100%;
}

@media (min-width: 768px) {
  .cardSize {
    width: 425px;
    height: 100%;
  }
}
.rowHeight {
  height: 65px;
}

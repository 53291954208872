.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px;
}
.cardFavoriteold {
  background-color: lightgreen;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  padding: 8px;
}
.iconLeft {
  align-items: center;
}

.iconWrap {
  width: 23px;
  height: 50px;
  overflow: hidden;
  display: inline-flex;
  vertical-align: middle;
}

.icon {
  width: 100%;
}

.cardDetails {
  flex: 1;
}

.detailsContainer {
  display: flex;
}
.actionButton {
  width: 100%;
}
.img {
  max-width: 50px;
  height: 50px;
}

.textBody {
  font-family: sans-serif, Poppins;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 100 !important;
}
.cardContainer {
  width: 22rem !important;
  height: 30rem !important;
  cursor: pointer;
}
.cardRow {
  justify-content: center;
}
@media (min-width: 768px) {
  .cardContainer {
    width: 30rem !important;
    height: 20rem !important;
    cursor: pointer;
  }
}

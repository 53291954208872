$circlew: 15px;
$circleh: 15px;
$circlemargin: 5px;
$loaderw: $circlew * 3 + $circlemargin * 2;
$loaderh: $circleh * 3 + $circlemargin * 2;
$delayenter: 0.3s;
$topenter: -10px;

@mixin transition($value) {
  -webkit-transition: $value;
  -moz-transition: $value;
  transition: $value;
}

@mixin delay($delay) {
  -webkit-animation-delay: $delay;
  -moz-animation-delay: $delay;
  animation-delay: $delay;
}
@-webkit-keyframes enter {
  0% {
    opacity: 0;
    top: $topenter;
  }
  5% {
    opacity: 1;
    top: 0px;
  }
  50.9% {
    opacity: 1;
    top: 0px;
  }
  55.9% {
    opacity: 0;
    top: -$topenter;
  }
}
@keyframes enter {
  0% {
    opacity: 0;
    top: $topenter;
  }
  5% {
    opacity: 1;
    top: 0px;
  }
  50.9% {
    opacity: 1;
    top: 0px;
  }
  55.9% {
    opacity: 0;
    top: -$topenter;
  }
}
@-moz-keyframes enter {
  0% {
    opacity: 0;
    top: $topenter;
  }
  5% {
    opacity: 1;
    top: 0px;
  }
  50.9% {
    opacity: 1;
    top: 0px;
  }
  55.9% {
    opacity: 0;
    top: -$topenter;
  }
}

body {
  background: #fdffff;
}
* {
  margin: 0;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -$loaderw / 2;
  margin-top: -$loaderh / 2;
}
.circle {
  border-radius: 50%;
  background: #28a745;
  width: $circlew;
  height: $circleh;
  float: left;
  top: $topenter;
  margin-right: $circlemargin;
  margin-top: $circlemargin;
  position: relative;
  opacity: 0;
  -webkit-animation: enter 6s infinite;
  animation: enter 6s infinite;
}
.enter {
  top: 0px;
  opacity: 1;
}
.circle:nth-child(1) {
  @include delay(6 * $delayenter);
}
.circle:nth-child(2) {
  @include delay(7 * $delayenter);
}
.circle:nth-child(3) {
  @include delay(8 * $delayenter);
  background: #fdc96f;
}
.circle:nth-child(4) {
  @include delay(3 * $delayenter);
}
.circle:nth-child(5) {
  @include delay(4 * $delayenter);
}
.circle:nth-child(6) {
  @include delay(5 * $delayenter);
}

.circle:nth-child(8) {
  @include delay(1 * $delayenter);
}
.circle:nth-child(9) {
  @include delay(2 * $delayenter);
}
.clear {
  clear: both;
}
.last {
  margin-right: 0;
}
